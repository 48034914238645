import { Component, Inject, OnInit } from '@angular/core';
import {
  FormArray,
  FormGroup,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import {
  ManagementService,
  SensorsSensor,
  SensorsService,
  TrigonosDatasource,
} from 'src/app/api/generated';
import { DataChangedService } from 'src/app/data-changed.service';
import { ReportModulesService } from 'src/app/services/report-modules/report-modules.service';
import {
  fieldsToOldRepresentation,
  GenericCorrelationConfig,
  getMinMaxFromConfig,
  MinMaxValue,
  sensor_to_field_map,
} from '../../correlation.tools';

import * as uuid from 'uuid';
import { validTime } from 'src/app/tools/validators';
import { toNumberFromENString, toNumberStringDE } from 'src/app/user/tools';
import { moveItemInArray } from '@angular/cdk/drag-drop';

export interface VisualisableField {
  id: string;
  label: string;
  unit: string;
}

@Component({
  selector: 'app-correlation-generic-config',
  templateUrl: './correlation-config.component.html',
  styleUrls: ['./correlation-config.component.scss'],
})
export class GenericCorrelationConfigComponent implements OnInit {
  public correlationForm: UntypedFormGroup;
  public availableDataSources: TrigonosDatasource[] = [];
  public availableSensors: Map<string, SensorsSensor[]> = undefined;
  public availableFields: VisualisableField[] = [];
  public editMode = false;
  public selectedFields = [];
  public defaultSelectedFieldControl = new UntypedFormControl();

  public fieldNameForId = (id: string): string => {
    const field = this.availableFields.find((f) => f.id === id);
    return field ? field.label : '';
  };

  public selectedStationsForField(formGroups: FormGroup[]): string {
    const enabled = formGroups.map((fg) => {
      return fg.controls.checked.value == true ? 1 : 0;
    });
    // Sum up the enabled stations
    const sum = enabled.reduce((a, b) => a + b, 0);
    return sum.toString();
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: GenericCorrelationConfig,
    private dialogRef: MatDialogRef<GenericCorrelationConfig>,
    private formBuilder: UntypedFormBuilder,
    private reportModuleService: ReportModulesService,
    private managementService: ManagementService,
    private dataChangedService: DataChangedService,
    private sensorService: SensorsService
  ) {}

  public isSensorCheckedForFieldInConfig(
    sensor: string,
    field: string
  ): boolean {
    if (!this.editMode) return false;
    const sensors = this.data.sensorFields.filter(
      (sf) => sf.fieldName === field
    );

    if (sensors.length === 0) {
      return false;
    }

    const sensorIds = sensors[0].sensors.filter((s) => s.influx_name == sensor);

    if (sensorIds.length === 0) {
      return false;
    }

    return sensorIds[0].checked;
  }

  ngOnInit(): void {
    this.editMode = this.data !== null;
    this.correlationForm = this.formBuilder.group({
      guid: [this.editMode ? this.data?.guid : uuid.v4()],
      type: ['correlation-generic'],
      headline: [
        this.data?.headline ? this.data?.headline : '',
        Validators.required,
      ],
      labelXAxis: [
        this.data?.x_label ? this.data?.x_label : '',
        Validators.required,
      ],
      labelYAxis: [
        this.data?.y_label ? this.data?.y_label : '',
        Validators.required,
      ],
      // datasources: this.formBuilder.array([]),
      // visualisableFields: this.formBuilder.array([]),
      sensorFields: this.formBuilder.array([]),
      timePeriodFiltered:
        this.data?.filter.enabled === undefined
          ? false
          : this.data?.filter.enabled,
      timePeriodFilteredFrom: [
        this.data?.filter.hourStart ? this.data?.filter.hourStart : 22,
        [Validators.required, Validators.min(0), Validators.max(23)],
      ],
      timePeriodFilteredTill: [
        this.data?.filter.hourEnd ? this.data?.filter.hourEnd : 6,
        [Validators.required, Validators.min(0), Validators.max(23)],
      ],
      yAxisMinValues: this.formBuilder.array([]),
      relativeZero: [
        this.data?.relative_zero === undefined
          ? false
          : this.data.relative_zero,
      ],
      defaultSelectedField: this.defaultSelectedFieldControl,
    });

    this.sensorService
      .sensorsProjectRead(this.reportModuleService.currentReport.project)
      .subscribe((sensors) => {
        this.availableFields = fieldsToOldRepresentation(sensors);
        const fields_sensor_map = sensor_to_field_map(sensors);
        this.availableSensors = fields_sensor_map;

        // Sort the keys based on displayName
        const sortedFields = Array.from(fields_sensor_map.keys()).sort(
          (a, b) => {
            const displayNameA = this.fieldNameForId(a);
            const displayNameB = this.fieldNameForId(b);
            console.log(displayNameA, displayNameB);
            return displayNameA.localeCompare(displayNameB);
          }
        );

        for (const field of sortedFields) {
          const sensorsArray = this.formBuilder.array(
            fields_sensor_map
              .get(field)
              .sort((sensor1: SensorsSensor, sensor2: SensorsSensor) => {
                return sensor1.display_name.localeCompare(
                  sensor2.display_name,
                  undefined,
                  { numeric: true }
                );
              })
              .map((sensor) =>
                this.formBuilder.group({
                  checked: this.formBuilder.control(
                    this.isSensorCheckedForFieldInConfig(
                      sensor.influx_name,
                      field
                    )
                  ),
                  id: this.formBuilder.control(sensor.id),
                  name: this.formBuilder.control(sensor.display_name),
                  influx_name: this.formBuilder.control(sensor.influx_name),
                })
              )
          );

          (this.correlationForm.get('sensorFields') as FormArray).push(
            this.formBuilder.group({
              fieldName: field,
              displayName: this.fieldNameForId(field),
              sensors: sensorsArray,
              allSelected: this.formBuilder.control({
                value: false,
                disabled: false,
              }),
            })
          );
        }

        this.generateMinMaxFormGroup();
        this.correlationForm.updateValueAndValidity();
        this.areAllSensorsChecked();
      });
  }

  private generateMinMaxFormGroup() {
    Array.from(this.availableSensors.keys()).forEach((field) => {
      // Add min max fields to form
      let minMax: MinMaxValue = {
        id: field,
        min: 0,
        max: 0,
      };

      if (this.data?.guid) {
        minMax = getMinMaxFromConfig(field, this.data.min_max);
      }

      this.yAxisMinValuesFormGroup().push(
        new UntypedFormGroup({
          id: new UntypedFormControl(field),
          min: new UntypedFormControl(minMax.min, [Validators.required]),
          max: new UntypedFormControl(minMax.max, [Validators.required]),
        })
      );
    });
  }

  public updateOrCreateModule() {
    if (!this.correlationForm.valid) {
      return;
    }

    let minMaxValues = this.correlationForm.value.yAxisMinValues.map(
      (group, index) => group
    );

    this.reportModuleService
      .addOrEditModuleToReport(
        <GenericCorrelationConfig>{
          guid: this.correlationForm.value.guid,
          type: this.correlationForm.value.type,
          headline: this.correlationForm.value.headline,
          x_label: this.correlationForm.value.labelXAxis,
          y_label: this.correlationForm.value.labelYAxis,
          filter: {
            enabled: this.correlationForm.value.timePeriodFiltered,
            hourStart: this.correlationForm.value.timePeriodFilteredFrom,
            hourEnd: this.correlationForm.value.timePeriodFilteredTill,
          },
          user_settings: {
            start_date: null,
            end_date: null,
          },
          min_max: minMaxValues,
          relative_zero: this.correlationForm.value.relativeZero,
          sensorFields: this.correlationForm.value.sensorFields,
          defaultSelectedField: this.correlationForm.value.defaultSelectedField,
          // sensorFields: newSensorFields,
        },
        this.editMode
      )
      .subscribe(
        (success) => {
          this.dialogRef.close();
          this.dataChangedService.configUpdated(
            this.reportModuleService.currentReport.id,
            this.correlationForm.value.guid
          );
        },
        (error) => {
          console.error(error);
        }
      );
  }

  get timeFilterDisabled(): string | null {
    return this.correlationForm.get('timePeriodFiltered').value ? null : '';
  }

  private yAxisMinValuesFormGroup(): UntypedFormArray {
    return this.correlationForm.get('yAxisMinValues') as UntypedFormArray;
  }

  private datasourcesFromFormGroup(): UntypedFormArray {
    return this.correlationForm.get('datasources') as UntypedFormArray;
  }

  private visualisableFieldsFromFormGroup(): UntypedFormArray {
    return this.correlationForm.get('visualisableFields') as UntypedFormArray;
  }

  get availableSensorsForForm(): { key: string; value: SensorsSensor[] }[] {
    const res = Array.from(this.availableSensors.entries()).map(
      ([key, value]) => ({
        key,
        value,
      })
    );

    return res;
  }

  onSensorChange(fieldIndex: number, sensorIndex: number): void {
    const fieldGroup = this.fields.at(fieldIndex) as FormGroup;
    const sensorsArray = fieldGroup.get('sensors') as FormArray;

    const allSelected = sensorsArray.controls.every(
      (sensorCtrl) => sensorCtrl.get('checked').value
    );
    fieldGroup.get('allSelected').setValue(allSelected);
    this.updateCheckFieldsFilter();
  }

  public updateCheckFieldsFilter() {
    const selectedFields = this.fields.controls
      .filter((field) => {
        const sensors = (field as FormGroup)
          .get('sensors')
          .value.filter((sensor) => sensor.checked);
        return sensors.length > 0;
      })
      .map((field) => field.value);
    this.selectedFields = selectedFields;

    if (
      !this.data.defaultSelectedField ||
      this.data.defaultSelectedField == ''
    ) {
      this.defaultSelectedFieldControl.setValue(
        this.selectedFields[0].fieldName
      );
    } else {
      if (this.selectedFields.length > 0) {
        this.defaultSelectedFieldControl.setValue(
          this.data.defaultSelectedField
        );
      }
    }
  }

  private areAllSensorsChecked(): void {
    for (let i = 0; i < this.fields.length; i++) {
      this.onSensorChange(i, 0);
    }
  }

  get fields(): FormArray {
    return this.correlationForm.get('sensorFields') as FormArray;
  }

  trackByFn(index: number, item: any): any {
    return item.fieldName; // or any unique property of the items
  }

  get sensorFields(): UntypedFormArray {
    return this.correlationForm.get('sensorFields') as UntypedFormArray;
  }

  onAllSelectedChange(fieldIndex: number, isChecked: boolean): void {
    const fieldGroup = this.fields.at(fieldIndex) as FormGroup;
    const sensorsArray = fieldGroup.get('sensors') as FormArray;

    // Set the value of each child sensor's checked property
    for (const sensorCtrl of sensorsArray.controls) {
      (sensorCtrl as FormGroup).get('checked').setValue(isChecked);
    }

    this.updateCheckFieldsFilter();
  }

  public sensorInfoForId(id: string) {
    return this.availableFields.filter((sensor) => sensor.id == id)[0];
  }
}
